<template>
  <div class="page-email page-content">
    <KlubbaRichText class="texts">
      <p><b>{{ progress.title }}</b></p>
      <p>{{ progress.description }}</p>
    </KlubbaRichText>

    <div class="container">
      <div class="card-wrap" :class="{ 'is-loading': loading }">
        <!-- Email -->
        <div class="page-other-section">
          <div class="page-other-label required">Email address</div>
          <klubba-input
            v-model="v$.newUserInfo.email.$model"
            :customClass="customClass"
            :class="{ 'error': v$.newUserInfo.email.$error }"
          />
        </div>

        <KlubbaLoaderIcon :active="loading" />
      </div>
    </div>

    <div class="button-wrap">
      <klubba-footer
        backButton="Back"
        @goPrevPage="prevPage"
        @goNextPage="nextPage"
        :loading="loading"
      />
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import { useVuelidate } from '@vuelidate/core';

import KlubbaFooter from '@/components/layout/KlubbaFooter.vue';
import KlubbaInput from '@/components/default/KlubbaInput.vue';
import KlubbaRichText from '../../components/default/KlubbaRichText.vue';
import firebaseApp from '../../config/firebase';
import KlubbaLoaderIcon from '../../components/default/KlubbaLoaderIcon.vue';

export default {
  components: {
    KlubbaLoaderIcon,
    KlubbaRichText,
    KlubbaFooter,
    KlubbaInput,
  },
  data: () => ({
    progress: {
      title: 'Let’s exchange email addresses',
      description: 'Please add an email address to your account for when SMS may not be possible.',
    },
    newUserInfo: {
      email: '',
    },
    customClass: 'border-b border-black border-opacity-10 h-8 pb-3',
    loading: false,
  }),
  setup: () => ({
    v$: useVuelidate(),
  }),
  computed: {
    club() {
      return this.$store.getters.club;
    },
    firebaseUser() {
      return this.$store.getters.firebaseUser;
    },
    userEmail() {
      return this.$store.getters.getUserKey('email');
    },
  },
  mounted() {
    this.newUserInfo.email = this.userEmail ?? '';
  },
  validations() {
    return {
      newUserInfo: {
        email: { required, email },
      },
    };
  },
  methods: {
    prevPage() {
      this.$router.back();
    },
    async nextPage() {
      if (this.v$.$invalid) {
        console.log('Please check invalid fields');
        return;
      }

      // Save info
      this.loading = true;
      const db = firebaseApp.firestore();
      await this.$store.commit('setNewUser', this.newUserInfo);

      const userQuery = await db.collection('users').doc(this.firebaseUser.uid).get();
      if (userQuery.exists) {
        // Update firebase
        await db.collection('users').doc(this.firebaseUser.uid).update(this.$store.getters.newUser)
          .then(() => {
            console.log('Firebase doc in users collection updated');
            this.$router.push(`/${this.club.id}/name`);
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      } else {
        this.loading = false;
        this.$router.push(`/${this.club.id}/name`);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.texts
  @apply p-8

.container
  @apply px-8

.card-wrap
  @apply bg-white rounded-radius-10 p-5 relative
  &.is-loading .page-other-section
    opacity: .3

.page-other-section
  @apply block
  transition: opacity var(--transition)

  &:not(:first-child)
    @apply mt-8

.page-other-label
  @apply text-sm text-black-light leading-4 tracking-spacing-7 mb-3
  &.required
    &::after
      content: ' *'
      @apply text-danger

.error
  @apply text-danger

.button-wrap
  @apply p-8 mt-auto
</style>
